<div class="container">
    <div [ngClass]="wrapperEnabled ? 'wrapper': ''">
        <div class="row" *ngIf="projectThumbnail.imageUrl">
            <div class="col-12">
                <div class="image-wrapper" [routerLink]="projectThumbnail.pageUrl">
                    <img [src]="projectThumbnail.imageUrl">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12" *ngIf="projectThumbnail.category">
                <div class="category" [routerLink]="projectThumbnail.pageUrl">
                    {{projectThumbnail.category}}
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12" *ngIf="projectThumbnail.title">
                <div class="title main-text-color" [routerLink]="projectThumbnail.pageUrl">
                    {{projectThumbnail.title}}
                </div>
            </div>
        </div>

    </div>
</div>

