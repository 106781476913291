<div class="italy">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-two></app-header-two>

        <div class="project-sections">
            <div class="project-section">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <app-project-thumbnail [projectThumbnail]="projectThumbnail1"
                                                   [standalone]="true"
                                                   [wrapperEnabled]="false"></app-project-thumbnail>
                        </div>
                    </div>
                </div>
            </div>

            <div class="project-section">
                <app-project-highlight [text]="projectThumbnail2.title"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                {{projectThumbnail2.description}}
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-image">
                                <img src="../../../assets/img/qb-overview.jpg">
                            </div>
                        </div>
                    </div>

                    <br>

                    <div class="row">
                        <div class="col-12">
                            <app-project-specs [projectSpecs]="projectSpecs"></app-project-specs>
                        </div>
                    </div>
                </div>
            </div>

            <div class="project-section">
                <app-project-highlight [text]="projectThumbnail3.title"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                {{projectThumbnail3.description}}
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-image">
                                <img src="../../../assets/img/qb-my-role.jpg">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="project-section">
                <app-project-highlight [text]="projectThumbnail4.title"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                {{projectThumbnail4.description}}
                            </div>
                        </div>
                    </div>

                    <div *ngFor="let challenge of challengesProjectSubsections">
                        <div class="row">
                            <div class="col-12">
                                <div class="project-subtitle">
                                    {{challenge.title}}
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="project-description">
                                    {{challenge.description}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-image">
                                <img src="../../../assets/img/qb-challenges-timeline.jpg">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="project-section">
                <app-project-highlight [text]="projectThumbnail5.title"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                {{projectThumbnail5.description}}
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-image">
                                <img src="../../../assets/img/qb-user-type.jpg">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="project-section">
                <app-project-highlight [text]="projectThumbnail6.title"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                {{projectThumbnail6.description}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="project-section">
                <app-project-highlight [text]="projectThumbnail7.title"></app-project-highlight>

                <div class="container">
                    <div *ngFor="let phase of discoveryPhasesProjectSubsections">
                        <div class="row">
                            <div class="col-12">
                                <div class="project-subtitle">
                                    {{phase.title}}
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="project-description">
                                    {{phase.description}}
                                </div>
                            </div>

                            <div class="col-12" *ngIf="phase.additionalHTMLContext">
                                <div class="project-description">
                                    <div [innerHTML]="phase.additionalHTMLContext"></div>
                                </div>
                            </div>


                            <div class="col-12" *ngIf="phase.imageUrl">
                                <div class="project-image">
                                    <img [src]="phase.imageUrl">
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="project-section">
                <app-project-highlight [text]="projectThumbnail8.title"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                {{projectThumbnail8.description}}
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="embed-responsive embed-responsive-4by3">
                                <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/943056761?autoplay=1&loop=1&autopause=0"
                                        frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div *ngFor="let step of customQuizStepsProjectSubsections">
                        <div class="row">
                            <div class="col-12">
                                <div class="project-subtitle">
                                    {{step.title}}
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="project-description">
                                    {{step.description}}
                                </div>
                            </div>

                            <div class="col-12" *ngIf="step.additionalHTMLContext">
                                <div class="project-description">
                                    <div [innerHTML]="step.additionalHTMLContext"></div>
                                </div>
                            </div>

                            <div class="col-12" *ngIf="step.imageUrl">
                                <div class="project-image">
                                    <img [src]="step.imageUrl">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="project-section">
                <app-project-highlight [text]="projectThumbnail9.title"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                {{projectThumbnail9.description}}
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-sm-12" *ngFor="let rn of impactResultNumbers">
                            <app-result-number [resultNumber]="rn"></app-result-number>
                        </div>
                    </div>
                </div>
            </div>


            <div class="project-section">
                <app-project-highlight [text]="projectThumbnail10.title"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                {{projectThumbnail10.description}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div *ngFor="let improvement of improvementsProjectSubsections">
                        <div class="row">
                            <div class="col-12">
                                <div class="project-subtitle">
                                    {{improvement.title}}
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="project-description">
                                    {{improvement.description}}
                                </div>
                            </div>

                            <div class="col-12" *ngIf="improvement.imageUrl">
                                <div class="project-image">
                                    <img [src]="improvement.imageUrl">
                                </div>
                            </div>

                            <div class="col-12" *ngIf="improvement.vimeoUrl">
                                <div class="embed-responsive embed-responsive-4by3">
                                    <iframe class="embed-responsive-item" [src]="improvement.vimeoUrl | safe: 'resourceUrl' "
                                            frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="project-section">
                <app-project-highlight [text]="projectThumbnail11.title"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                {{projectThumbnail11.description}}
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-image">
                                <img src="../../../assets/img/qb-v2.jpg">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="project-section">
                <app-project-highlight [text]="projectThumbnail12.title"></app-project-highlight>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="project-description">
                                {{projectThumbnail12.description}}
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="project-image">
                                <img src="../../../assets/img/qb-v2-impact.jpg">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <app-spacer [height]="36"></app-spacer>

            <app-footer></app-footer>
        </div>
    </div>
</div>
