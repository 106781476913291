<div class="italy">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-two></app-header-two>
        <div class="project-sections">
            <div class="project-section">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <app-spacer height="32"></app-spacer>
                            <app-project-thumbnail [projectThumbnail]="projectThumbnail1"
                                                   [standalone]="true"
                                                   [wrapperEnabled]="false"></app-project-thumbnail>
                        </div>
                    </div>
                </div>
            </div>

            <div class="project-section">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <app-spacer height="32"></app-spacer>
                            <app-project-thumbnail [projectThumbnail]="projectThumbnail2"
                                                   [standalone]="true"
                                                   [wrapperEnabled]="false"></app-project-thumbnail>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <hr>
                    </div>
                </div>
            </div>

            <div class="project-section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-sm-12" *ngFor="let pt of projectThumbnails">
                           <app-project-card [projectThumbnail]="pt"></app-project-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
</div>