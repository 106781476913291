import { Component, OnInit } from '@angular/core';
import {ProjectThumbnail} from "../../daos/project-thumbnail";
import {ProjectSpec} from "../../daos/project-spec";
import {BasicDao} from "../../daos/basic-dao";
import {ResultNumber} from "../features/result-number";

@Component({
  selector: 'kaplan-intro',
  templateUrl: './kaplan-intro.component.html',
  styleUrls: ['./kaplan-intro.component.css']
})
export class KaplanIntroComponent implements OnInit {

  projectThumbnail1 :ProjectThumbnail = {
    title: "About Kaplan & My Role",
    category: null,
    description: "I’m the UX designer owning all (B2B) Educator designs while regularly collaborating with UX researcher(s) " +
        "and seeking feedback from UX designers in different workstreams. During the process of designing and delivering " +
        "this quiz-centered eco-system, I also worked closely and collaboratively with Product, Engineering, Business, " +
        "Content, and Learning Experience/Psychometrics teams.",
    imageUrl: null,
    pageUrl: null
  };

  projectThumbnail2 :ProjectThumbnail = {
    title: null,
    category: null,
    description: null,
    imageUrl: "assets/img/kaplan-diagram.png",
    pageUrl: null
  };

  projectThumbnails: ProjectThumbnail[] = [{
    title: "Quiz Building, Scheduling, Managing Eco-System",
    category: "Product Design - Ecosystem Design",
    description: null,
    imageUrl: "assets/img/kaplan-diagram.png",
    pageUrl: "/kaplan-quiz-builder"
  }, {
    title: "Course Progress",
    category: "Data Visualization",
    description: null,
    imageUrl: "assets/img/kaplan-diagram.png",
    pageUrl: "/kaplan-reporting"
  }, {
    title: "Dashboard",
    category: "Product Design - SaaS",
    description: null,
    imageUrl: "assets/img/kaplan-diagram.png",
    pageUrl: "/kaplan-dashboard"
  }, {
    title: "Coffee Design System",
    category: "Design System",
    description: null,
    imageUrl: "assets/img/kaplan-diagram.png",
    pageUrl: "/kaplan-design-system"
  }];

  constructor() { }

  ngOnInit(): void {
  }

}
