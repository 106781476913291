import { Component, OnInit } from '@angular/core';
import {ProjectThumbnail} from "../../daos/project-thumbnail";
import {ProjectSpec} from "../../daos/project-spec";
import {BasicDao} from "../../daos/basic-dao";
import {ResultNumber} from "../features/result-number";

@Component({
  selector: 'app-case-study-lecco',
  templateUrl: './case-study-kaplan-reporting.component.html',
  styleUrls: ['./case-study-kaplan-reporting.component.scss']
})
export class CaseStudyKaplanReportingComponent implements OnInit {
  projectThumbnail1 :ProjectThumbnail = {
    title: null,
    category: null,
    description: null,
    imageUrl: "assets/img/nycdsa_hero.png",
    pageUrl: null
  };

  projectThumbnail2 :ProjectThumbnail = {
    title: "Reporting...Reporting...Reporting...Reporting...Reporting...Reporting...",
    category: "Fire Island Pines Property Owners Association",
    description: "At NYC Data Science Academy, I have worked on a diverse set of projects to improve the user experience and increase the\n" +
      "                                conversion rate. The projects include redesigning more engaging web pages of the most important products, simplifying\n" +
      "                                the current online application process, and designing new web products.",
    imageUrl: null,
    pageUrl: null
  };

  projectSpecs: ProjectSpec[] = [{
    icon: "fas fa-map",
    title: "Project Scope",
    description: "Information Architecture <br> UI/UX Design",
    color: "#6c91ef",
  }, {
    icon: "fas fa-gem",
    title: "My Role",
    description: "UX Designer <br> UI Designer",
    color: "#6c91ef",
  }, {
    icon: "fas fa-magic",
    title: "Tools",
    description: "Figma, Trello",
    color: "#6c91ef",
  }, {
    icon: "fas fa-clock",
    title: "Timeline",
    description: "3 Months <br> Feb 2021 - Apr 2021",
    color: "#6c91ef",
  }];

  featuresData = {
    title: null,
    description: "We achieved a problem statement through various research approaches, which included conducting user surveys and interviews, " +
      "usability testing, competitor and comparator analysis, and heuristics analysis. ",
    resultNumbers: [{
      title: "Surveys",
      resultNumber: 16,
      icon: "fas fa-clipboard"
    }, {
      title: "Interviews",
      resultNumber: 13,
      icon: "fas fa-users"
    }, {
      title: "Usability Tests",
      resultNumber: 15,
      icon: "fas fa-poll"
    }],
    projectSpecs: [{
      title: "Heuristic Analysis",
      description: 'We used Abby Method to analyze FIPPOA website. 6/10 heuristics had major issues.',
      icon: "far fa-frown",
      color: "#F76464"
    }, {
      title: "Accessibility",
      description: 'The current website neither optimized for screen readers, nor met the 3.0 standard.',
      icon: "far fa-meh",
      color: "#FA9A64"
    }, {
      title: "Competitor Analysis",
      description: 'FIPPOA website lacked of latest information and intriguing visuals.',
      icon: "far fa-frown",
      color: "#F76464"
    }, {
      title: "Comparator Analysis",
      description: 'The current FIPPOA website lacked of a clear information architecture.',
      icon: "far fa-meh",
      color: "#FA9A64"
    }]
  };

  projectIssues: BasicDao[] = [{
    title: "Time-Consuming Navigation",
    description: "There were a lot of pages and external links without a clear, yet intuitive navigation menu. Users had to click back " +
      "and forth to locate information, which resulted in a high exit rate before landing the destination and low retention.",
    url: "../../../assets/img/time-consuming-navigation.jpg"
  }, {
    title: "Outdated and Inconsistent Design",
    description: "The website was inconsistent between pages while using low-resolution images and outdated visual design across the site. " +
      "Many users bounced at the homepage as they either lost interest to further explore the website. ",
    url: "../../../assets/img/outdated-design.jpg"
  }, {
    title: "Text Heavy Content",
    description: "The current website relied on using text-heavy content to convey information. Users not only lost interest quickly but also " +
      "had trouble finding the most useful information out of a long article at a glance. ",
    url: "../../../assets/img/text-heavy.jpg"
  }, {
    title: "Confusing Content",
    description: "Short-term renters and visitors were unsure about whether FIPPOA resources were available to them. There was no access " +
      "point for them to learn about FIPPOA membership even though the membership is open to all.",
    url: "../../../assets/img/confusing content.jpg"
  }];

  impactResultNumbers: ResultNumber[] = [{
    title: "Test Success Rate",
    resultNumber: 500,
    icon: "fas fa-arrow-up",
    postFix: "%"
  }, {
    title: "Time-on-task",
    resultNumber: 13.9,
    icon: "fas fa-arrow-down",
    postFix: " sec"
  }];
  constructor() { }

  ngOnInit(): void {
  }

}




