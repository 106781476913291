import { Component, OnInit } from '@angular/core';
import {ProjectThumbnail} from "../../daos/project-thumbnail";
import {ProjectSpec} from "../../daos/project-spec";
import {BasicDao} from "../../daos/basic-dao";
import {ResultNumber} from "../features/result-number";

@Component({
  selector: 'app-case-study-lecco',
  templateUrl: './case-study-kaplan-quiz-builder.component.html',
  styleUrls: ['./case-study-kaplan-quiz-builder.component.scss']
})
export class CaseStudyKaplanQuizBuilderComponent implements OnInit {
  projectThumbnail1 :ProjectThumbnail = {
    title: null,
    category: null,
    description: null,
    imageUrl: "assets/img/qb-top-image.jpg",
    pageUrl: null
  };

  projectThumbnail2 :ProjectThumbnail = {
    title: "Creating a Quiz Building, Scheduling, and Managing Eco-System for Educators",
    category: "",
    description: "This project was developed and improved in phases over a two-year period (2022-2023) with multiple " +
        "rounds of research, iterations, and testing. Adding Quiz Builder, Library, and Scheduler to the Educator product " +
        "portfolio has created a quiz-centric eco-system that enables faculty to create and assign custom assessments " +
        "tailored to their student's needs, ultimately leading to their success. " +
        "The launch of this quiz-centric eco-system has not only empowered end users in their learning journey " +
        "but has also helped Kaplan save millions of dollars in contracts.",
    imageUrl: null,
    pageUrl: null
  };

  projectThumbnail3 :ProjectThumbnail = {
    title: "My Role",
    category: "",
    description: "I’m the product designer owning (B2B) Educator designs with regular collaboration with UX researcher(s), " +
        "designers in different workstreams, product managers/owners, engineers. I also worked closely with the Business, " +
        "Content, and Learning Experience/Psychometrics teams in the process of quiz-centric eco-system delivery.",
    imageUrl: null,
    pageUrl: null
  };

  projectThumbnail4 :ProjectThumbnail = {
    title: "Challenges along the Way",
    category: "",
    description: "The three products in this Eco-system weigh differently in project scope, design, and research needs. " +
        "The scheduler is majorly UI work, which launched first. Quiz Builder is the most complex with a comprehensive " +
        "process that follows while Quiz Library, a medium-scope project add-on of Quiz Builder, launches at last. " +
        "Two major challenges persist throughout the phases.",
    imageUrl: null,
    pageUrl: null
  };

  challengesProjectSubsections: ProjectThumbnail[] = [{
    title: "Challenge 1: Tight Timeline",
    category: "",
    description: "As both our engineering team and I were on a tight delivery timeline, I had to be flexible in design " +
        "and research strategies to hand off the low-risk design first to yield more time to test on higher-risk " +
        "designs with improvement features.",
    imageUrl: null,
    pageUrl: null
  }, {
    title: "Challenge 2: Major Team Restructure",
    category: "",
    description: "A major team restructure happened in Q1 2022, in which the team size shrank from 14 to 6. " +
        "In the following 6+ months without managers, senior designers, and UX researchers, " +
        "I worked directly with the Product, Engineering, and Business to align important timelines, " +
        "roadmap, and product requirements in this eco-system..",
    imageUrl: null,
    pageUrl: null
  }];

  projectThumbnail5 :ProjectThumbnail = {
    title: "User Type",
    category: "",
    description: "Educator (B2B) products have three major user types. External faculty are the primary users of " +
        "this quiz-centered ecosystem, while the internal Kaplan academic team is a secondary user as they " +
        "provide training and service support to external educators.",
    imageUrl: null,
    pageUrl: null
  };

  projectThumbnail6 :ProjectThumbnail = {
    title: "How might we help educators create custom quizzes of target topics?",
    category: "",
    description: "Creating a custom quiz in addition to Kaplan’s pre-stiched ones is the starting point of a " +
        "faculty’s journey to help students improve their performance in target areas. As there is no such product, " +
        "my scope of work is to deliver a self-service tool that fulfills end users’ needs, which went through the " +
        "following major process.",
    imageUrl: null,
    pageUrl: null
  };

  projectThumbnail7 :ProjectThumbnail = {
    title: "How might we help educators create custom quizzes of target topics?",
    category: "",
    description: "",
    imageUrl: null,
    pageUrl: null
  };

  discoveryPhasesProjectSubsections: ProjectThumbnail[] = [{
    title: "Revalidating the needs",
    category: "",
    description: "Previous researchers first raised and validated the need for Quiz Builder in 2019. However, " +
        "the project was interrupted due to company reformation until 2022. Since I was on a tight timeline, " +
        "I started with past documentation and then quickly revalidated the problem space and user needs with" +
        " our Customer Engagement Team, who received direct feedback from partner institutions.",
    imageUrl: "assets/img/qb-revalidating-needs.jpg",
    pageUrl: null
  }, {
    title: "Reassessing previously proposed design",
    category: "",
    description: "The 2019 Quiz Builder design includes a built-in assigning ability that was inseparable from " +
        "the quiz creation process. I learned about some user behavior changes from the earlier revalidation " +
        "process and realized that the 2019 version would lead to an extremely frustrating experience. Two key " +
        "findings were:",
    imageUrl: "assets/img/qb-previous-journey.jpg",
    pageUrl: null,
    additionalHTMLContext: "<ul>" +
        "  <li>&nbsp; • Faculty did not always assign the quiz right after creating one.</li>" +
        "  <li>&nbsp; • The same quiz can be used in pairs with a course over years and years.</li>" +
        "</ul>  "
  }];

  projectThumbnail8 :ProjectThumbnail = {
    title: "Drilling down into the MVP - a simple 3-step flow to build a custom quiz",
    category: "",
    description: "With a defined problem, goals, and constraints, I started designing, testing, and iterating. " +
        "Quiz Builder 1.0 is a 3-step flow that allows educators to create custom quizzes of target topics in one " +
        "go by selecting topics & settings, handpicking questions, and reviewing details. \n",
    imageUrl: null,
    pageUrl: null
  };

  customQuizStepsProjectSubsections: ProjectThumbnail[] = [{
    title: "Flexible ways to select target topics",
    category: "",
    description: "Faculty can make initial topic selections in the first step of the process while further adjusting " +
        "the selection in the following step. With the built-in flexibility of topic selection, educators " +
        "can reach a desired question list with either a top-down or bottom-up approach.",
    imageUrl: "assets/img/qb-feature-add-questions.jpg",
    pageUrl: null
  }, {
    title: "Easy access to view question details",
    category: "",
    description: "I initiated three ways of previewing questions and associated attributes. A side sheet appearing " +
        "on click was used, considering users’ preferences, and the UI component’s accessibility, scalability, and " +
        "consistency. The state change of ‘View’/’Viewed’ on each question card was well-noted during usability testing.",
    imageUrl: "assets/img/qb-feature-question-preview.jpg",
    pageUrl: null,
  }, {
    title: "A concise summary of question selection and question randomizer",
    category: "",
    description: "During the moderated testing with 8 faculty at 6 different medical institutions, educators expressed their needs to:\n" +
        "Glance at the topic and difficulty level distribution of the quiz quickly\n" +
        "Ensure academic integrity by randomizing question order for each student while organizing questions in a certain order on their end. \n",
    imageUrl: null,
    pageUrl: null,
    additionalHTMLContext: "<ul>" +
        "  <li>&nbsp; • Glance at the topic and difficulty level distribution of the quiz quickly</li>" +
        "  <li>&nbsp; • Ensure academic integrity by randomizing question order for each student while organizing questions in a certain order on their end.</li>" +
        "</ul>  "
  }, {
    title: "",
    category: "",
    description: "These two improvements were tested in another round of unmoderated testing and integrated into Quiz Builder v.1.0.",
    imageUrl: "assets/img/qb-feature-summary.jpg",
    pageUrl: null
  }];

  projectThumbnail9 :ProjectThumbnail = {
    title: "Quiz Builder 1.0 Impacts: Empowering Learning and Growth",
    category: "",
    description: "Quiz Builder 1.0 was introduced to partner medical institutions in the US and overseas in October 2022. " +
        "The launch delivered 5M ROI for 2022 and saved 1M in 2023 (Jan. - Jul.). In addition to the business impact," +
        " educators noted Quiz Builder 1.0 as easy to use and self-sufficient.",
    imageUrl: null,
    pageUrl: null
  };

  impactResultNumbers: ResultNumber[] = [{
    title: "ROI in 2022",
    resultNumber: 5,
    icon: "fa fa-dollar-sign",
    postFix: " Million"
  }, {
    title: "Contract Saved in 2023",
    resultNumber: 1,
    icon: "fas fa-arrow-down",
    postFix: " Million"
  }, {
    title: "UX-Lite Score",
    resultNumber: 111,
    icon: "fa fa-star",
    postFix: ""
  }];

  projectThumbnail10 :ProjectThumbnail = {
    title: "Advancing Quiz Builder with Better UX for More Tenants",
    category: "",
    description: "With the success of Quiz Builder 1.0, one of the major 2023 initiatives is to provide " +
        "nursing institutions (Kaplan’s largest institutional business segment) with Quiz Builder. I worked with" +
        " my product manager, lead engineer, and key business stakeholders to debrief requested improvements " +
        "and desired features learned from previous moderated and unmoderated testings, as well as post-launch" +
        " continuous research. After the prioritization activity and a deep dive into analyzing the difference " +
        "between nursing and medical user needs, the following improvements were delivered in v.1.0 to v.1.3 release.",
    imageUrl: null,
    pageUrl: null
  };

  improvementsProjectSubsections: ProjectThumbnail[] = [{
    title: "Search: a more efficient topic selection",
    category: "",
    description: "A nursing taxonomy can have more than 1000 topics. An in-taxonomy search function was integrated to " +
        "help faculty quickly filter down to a specific topic in mind.",
    imageUrl: null,
    pageUrl: null,
    vimeoUrl: "https://player.vimeo.com/video/943057176?autoplay=1&loop=1&autopause=0"
  }, {
    title: "Question Set: a more flexible component to feature different question types",
    category: "",
    description: "One of the pain points learned in the 2022 Quiz Builder was the inability to support question sets " +
        "like in real tests. As this is a shared need also confirmed by the nursing team, I designed a new " +
        "component and expanded the table UI to feature a series of questions.",
    imageUrl: "assets/img/qb-feature-questions-set.jpg",
    pageUrl: null
  }, {
    title: "Improved Preview: better adapting the content",
    category: "",
    description: "To better adapt non-text content like a data table, I advocated upgrading the existing fixed-width " +
        "side sheet to a draggable one. The ability to open a Test-like UI preview in a new tab was also available " +
        "based on feedback received.",
    imageUrl: null,
    pageUrl: null,
    vimeoUrl: "https://player.vimeo.com/video/943057191?autoplay=1&loop=1&autopause=0"
  }];

  projectThumbnail11 :ProjectThumbnail = {
    title: "Sneak Peak of Quiz Builder 2.0: Defining Topic Distribution with Building Blocks",
    category: "",
    description: "Quiz Builder 2.0 addresses the users' most wanted feature, creating a custom test by topic " +
        "distribution. Instead of a top-down or bottom-up approach in Quiz Builder 1.0, educators can define " +
        "criteria for a question set while adding multiple sets to curate a question list with target topics" +
        " quickly in v.2.0. Educators describe this revolutionary way of creating a test as ‘exactly what they " +
        "want since day 1’ during testing.",
    imageUrl: null,
    pageUrl: null
  };

  projectThumbnail12 :ProjectThumbnail = {
    title: "Quiz Builder’s Impact on Nursing Institutions: internally & externally",
    category: "",
    description: "Quiz Builder (v.1.3) launched on Oct.3rd, 2023. This launch has empowered nursing faculty at " +
        "78 institutions with the self-service ability to create custom tests. 418 custom tests were created and " +
        "taken by 6k students in the first 3 months of the launch. Internally, this launch helps save business costs" +
        " by transforming the 4-week min. process involving multiple teams to a 1-day max. experience to " +
        "the end user. ",
    imageUrl: null,
    pageUrl: null
  };


  projectSpecs: ProjectSpec[] = [{
    icon: "fas fa-map",
    title: "Project Scope",
    description: "Information Architecture <br> UI/UX Design",
    color: "#6c91ef",
  }, {
    icon: "fas fa-gem",
    title: "My Role",
    description: "UX Designer <br> UI Designer",
    color: "#6c91ef",
  }, {
    icon: "fas fa-magic",
    title: "Tools",
    description: "Figma, Trello",
    color: "#6c91ef",
  }, {
    icon: "fas fa-clock",
    title: "Timeline",
    description: "3 Months <br> Feb 2021 - Apr 2021",
    color: "#6c91ef",
  }];

  constructor() { }

  ngOnInit(): void {
  }

}




